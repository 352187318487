import React from 'react';
import { LogoProps } from './types';

function Moon({ width, height, color }: LogoProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.33994 1.51721C4.70895 1.51721 1.55176 4.2886 1.55176 8.72283C1.55176 13.1571 4.70895 16.4827 8.91854 16.4827C13.1281 16.4827 15.7591 13.1571 15.7591 10.3857C9.97094 14.2656 3.65655 7.61427 7.33994 1.51721Z"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Moon;
